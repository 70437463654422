import { FC, useRef, RefObject, Fragment, useCallback } from 'react'
import Link from 'next/link'
import { Badge, Button, Divider, Nav } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'
import SignOutIcon from '@rsuite/icons/legacy/SignOut'
import RsuiteProfileIcon from '@rsuite/icons/legacy/Profile'
import ArchiveIcon from '@rsuite/icons/legacy/Archive'
import CubesIcon from '@rsuite/icons/legacy/Cubes'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useAuth } from '../../../services/useAuth'
import usePersistLocale from '../../../services/usePersistLocale'
import SearchBar from './Search/Search'
import { HeaderLinks } from '../../../utils/types/misc'
import supplierDropdownLinks from '../../../utils/supplierDropdownLinks.json'
import buyerDropdownLinks from '../../../utils/buyerDropdownLinks.json'
import CountryPicker from './CountryPicker/CountryPicker'
import CartNav from './CartNav'
import NavigationLink from './NavigationLink'
import DroppeLogo from '../../Icons/DroppeLogo'
import useContactModal from '../../Modals/hooks/useContactModal'
import useIsOnMobile from '../../../services/useIsOnMobile'
import { ScreenSize, LOGIN_MODAL_TRIGGERED_PLACE_KEY, LoginModalTriggeredPlace } from '../../../utils/constants'
import withRequireAuth from '../../../services/withRequireAuth'
import useUrls from '../../../services/useUrls'
import HeartIcon from '../../Icons/SavedProducts/HeartIcon'
import { isSuperUser, isSupplier } from '../../../lib/supplier'
import useLoginModal from '../../../services/useLoginModal'
import ProfileIconPicker from './ProfileIconPicker/ProfileIconPicker'
import useSavedProduct from '../../hooks/useSavedProduct'
import SignInPicker from './SignInPicker/SignInPicker'

import buttonStyles from '../../../styles/CustomButtons.module.less'
import navStyles from '../../../styles/Navigation.module.less'

interface NavigationProps {
  headerRef: RefObject<HTMLDivElement>
}

const Navigation: FC<NavigationProps> = ({ headerRef }) => {
  const countryPickerRef = useRef<HTMLDivElement>(null)
  const searchRef = useRef<HTMLDivElement>(null)

  const { user } = useAuth()
  const { t } = useTranslation('common')
  const isOnMobileBlocker = useIsOnMobile(ScreenSize.MOBILE_BLOCKER_BREAKPOINT)
  // TODO: Remove when proper tablet screensize breakpoint designs are implemented
  const isScreenBelowTabletHeaderBreakpoint = useIsOnMobile(ScreenSize.TABLET_HEADER_BREAKPOINT)
  const { pushT } = useUrls()

  const { openLoginModal } = useLoginModal()

  const { savedProductsCount } = useSavedProduct()

  const handleProfileIconClick = useCallback((url: string, icon: 'profile' | 'heart') => {
    if (user) {
      pushT(url)
      return
    }

    // Using session storage to handle redirection logic in LoginModal
    if (icon === 'heart') {
      sessionStorage.setItem(LOGIN_MODAL_TRIGGERED_PLACE_KEY, LoginModalTriggeredPlace.headerHeart)
    }

    openLoginModal('main_header')
  }, [user, openLoginModal, pushT])

  // Feature flags - manage from Growthbook UI
  const saveProductFeatureFlag = useFeatureIsOn('product-card-heart-icon')
  const profileIconFeatureFlag = useFeatureIsOn('header-profile-icon')

  const openContactModal = useContactModal(
    'main_header_modal', // contact_context deprecated, will be removed later.
    'main_header_contact_modal', // context
  )

  const headerDropdownLinks = user?.siteid || user?.superuser === 1
    ? supplierDropdownLinks : buyerDropdownLinks
  usePersistLocale()

  // Since rsuite v5 requires icons to be imported as whole standalone components, we need to
  // convert the JSON icon names to their corresponding components and pass them on
  const toIconComponent = (iconName: string | undefined) => {
    switch (iconName) {
      case 'profile': {
        return <RsuiteProfileIcon />
      }
      case 'sign-out': {
        return <SignOutIcon />
      }
      case 'archive': {
        return <ArchiveIcon />
      }
      case 'cubes': {
        return <CubesIcon />
      }
      default: {
        return <></>
      }
    }
  }

  const shouldDisplayDropdownMenu = isSupplier(user) || isSuperUser(user)

  const DropdownMenu = shouldDisplayDropdownMenu ? withRequireAuth(() => (
    <Nav data-testid="manage-dropdown" className={`${navStyles['dropdown-trigger-vertical-fix']}`} pullRight>
      <Nav.Menu title={t('Manage')} trigger="click" placement="bottomEnd">
        {(headerDropdownLinks as HeaderLinks[])?.map((linkObject) => (
          <NavigationLink
            key={linkObject.label}
            href={linkObject.href}
            translateURL={linkObject.translateURL}
            label={linkObject.label}
            icon={toIconComponent(linkObject.iconName)}
            loggedIn={linkObject.loggedIn}
            className={linkObject.className?.split(' ').map((className) => buttonStyles[className]).join(' ')}
            loggedOut={linkObject.loggedOut}
            rightDropDown={linkObject.rightDropDown}
            topDivider={linkObject.topDivider}
          />
        ))}
      </Nav.Menu>
    </Nav>
  )) : Fragment

  return (
    <div className={navStyles['header-nav-content']}>
      <div className={navStyles['header-brand-container']}>
        <Link href="/">
          <DroppeLogo className={navStyles.logo} />
        </Link>
      </div>
      <div className={navStyles['search-bar-container']} ref={searchRef}>
        <SearchBar />
      </div>
      <Nav>
        <CountryPicker
        // Used to ensure whisper dropdown stays fixed when
        // scrolling by setting parent container to country picker
          countryPickerRef={countryPickerRef}
        />
      </Nav>
      <Nav className={navStyles['login-text-nav']}>
        <SignInPicker
          handleProfileIconClick={handleProfileIconClick}
          topHeaderRef={countryPickerRef}
        />
      </Nav>
      <Nav>
        {user === null
          ? (
            <Button
              appearance="ghost"
              className={`
                  ${buttonStyles['custom-button']}
                  ${buttonStyles['button-transparent']}
                  ${buttonStyles['button-caret']}
                  ${navStyles['header-nav-button']}
                  ${!isScreenBelowTabletHeaderBreakpoint ? 'margin-left-spacer-double' : ''}
                `}
              onClick={() => openContactModal()}
            >
              {t('Contact us')}
            </Button>
          )
          : <DropdownMenu />}
      </Nav>

      {saveProductFeatureFlag && (
        <Nav
          className={navStyles['heart-icon-nav']}
          onClick={() => handleProfileIconClick('/my-products', 'heart')}
        >
          <Badge content={savedProductsCount || false}>
            <HeartIcon
              className={navStyles['heart-icon']}
              variant="dark"
            />
          </Badge>
        </Nav>
      )}

      {profileIconFeatureFlag && (
        <Nav className={navStyles['profile-button-nav']}>
          <ProfileIconPicker
            handleProfileIconClick={handleProfileIconClick}
            topHeaderRef={countryPickerRef}
          />
        </Nav>
      )}

      {!isScreenBelowTabletHeaderBreakpoint && (
        <Divider vertical className={navStyles['vertical-divider']} />
      )}

      {!isOnMobileBlocker && (
        <Nav className={navStyles['cart-icon-nav']}>
          <CartNav
            headerRef={headerRef}
            className={isScreenBelowTabletHeaderBreakpoint ? '' : ''}
          />
        </Nav>
      )}
    </div>
  )
}

export default Navigation
