import { FC, ReactNode } from 'react'
import { Col, Placeholder } from 'rsuite'

interface MegaMenuLinksColumnProps {
  heading: string
  isLoadingData: boolean
  children: ReactNode
}

const MegaMenuLinksColumn: FC<MegaMenuLinksColumnProps> = (props) => {
  const { heading, children, isLoadingData } = props

  // Set random number of 7 to 10 rows for a more "dynamic" look
  const randomNumberOfSkeletonRows = Math.random() * (10 - 7) + 7

  return (
    <Col
      md={6}
      className="padding-left-zero padding-right-spacer-quadruple"
      data-testid="megamenu-column"
    >
      {isLoadingData ? (
        <Placeholder.Paragraph active rows={randomNumberOfSkeletonRows} rowHeight={15} />
      ) : (
        <>
          <h5 className="margin-bottom-spacer-double">
            {heading}
          </h5>
          {children}
        </>
      )}
    </Col>
  )
}

export default MegaMenuLinksColumn
