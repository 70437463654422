import Image, { ImageProps } from 'next/legacy/image'
import { FC } from 'react'

const ProductEmptyImage: FC<Omit<ImageProps, 'src'>> = (props) => (
  <Image
    src="/graphics/product-no-image.svg"
    alt="Product with no image"
    height={100}
    width={100}
    {...props}
  />
)

export default ProductEmptyImage
