import { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal, Stack } from 'rsuite'
import { Index, InstantSearch } from 'react-instantsearch'
import { useRouter } from 'next/router'
import omit from 'lodash/omit'
import useTranslation from 'next-translate/useTranslation'

import useMounted from '../../../../services/useMounted'
import SearchBoxRsuite from '../../../Search/SearchBoxRsuite'
import useUrls from '../../../../services/useUrls'
import { QueryParam } from '../../../../utils/constants'
import { typesenseInstantSearchAdapter } from '../../../../utils/typesense/config'
import ProductHits from '../../../Search/ProductHits'
import SupplierHits from '../../../Search/SupplierHits'
import { formatSearchTerm } from '../../../../utils/search'
import useSearchEvent from '../../../../services/analytics/useSearchEvent'
import { SearchResult, SearchResultsTypesenseProduct } from '../../../../utils/types/search'
import { getCountryAndLocaleStrings, getCurrencyCode } from '../../../../utils/locales'
import { isDefined } from '../../../../utils/types/misc'

import styles from '../../../../styles/Search.module.less'

interface SearchModalProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  isDropdownActive: boolean
  isNewSearch: boolean
  onClose: () => void
  externalSearchCallback: () => void
}

const SearchModal: FC<SearchModalProps> = (props) => {
  const {
    isOpen,
    setIsOpen,
    isDropdownActive,
    isNewSearch,
    onClose,
    externalSearchCallback,
  } = props

  const [searchResults, setSearchResults] = useState<SearchResultsTypesenseProduct | null>(null)

  const handleResultsChange = (results: SearchResultsTypesenseProduct) => {
    setSearchResults(results)
  }

  const { t } = useTranslation('products')
  const { query, locale: countryAndLocaleString } = useRouter()
  const { country } = getCountryAndLocaleStrings(countryAndLocaleString)
  const isMounted = useMounted()
  const { pushT } = useUrls()

  // When clearing search, pass all the queries apart from the search and filters
  const queriesWithoutSearchAndFilters = omit(query, [QueryParam.search, QueryParam.filters])

  // Transparent backdrop
  // isMounted needed to ensure window.document is available
  const searchBackDrop = isMounted && createPortal(
    <div className={`${styles['search-backdrop']} ${(isDropdownActive) ? styles.visible : ''}`} />,
    document.body,
  ) as ReactNode

  // Analytics event – calling triggerSearchEvent will trigger search analytics event instantly
  // instead of waiting for 3000ms debounce to finish. Used in case a search result is clicked
  // before search event is triggered.
  const triggerSearchEvent = useSearchEvent({
    searchTerm: searchResults?.query || '',
    resultQuantity: searchResults?.nbHits || 0,
    searchResults: searchResults?.hits.map((hit): SearchResult => ({
      item_id: hit.sku,
      item_name: hit.texts.en.name,
      price: hit.price,
      currency: getCurrencyCode(country),
      item_brand: hit.texts.brand ?? `${hit.supplier} (fallback, supplier name)`,
      supplier: hit.supplier,
      item_category: hit.categories.one?.en,
      item_category2: hit.categories.two?.en,
      item_category3: hit.categories.three?.en,
    })) || [],
    isLoading: !isDefined(searchResults),
  })

  return (
    <div>
      {searchBackDrop}
      <Modal
        open={isOpen && isNewSearch}
        size="md"
        onClose={onClose}
        dialogClassName={styles['search-modal-dialog']}
      >
        <InstantSearch indexName="products" searchClient={typesenseInstantSearchAdapter.searchClient}>
          <Stack direction="column" className={styles['search-modal-container']}>
            <Stack.Item>
              <SearchBoxRsuite
                gotoSearchPage={(searchQuery_: string) => {
                  setIsOpen(false)
                  externalSearchCallback()
                  pushT({
                    pathname: '/products',
                    query: {
                      ...(searchQuery_
                        ? { [QueryParam.search]: formatSearchTerm(searchQuery_, t) }
                        : queriesWithoutSearchAndFilters
                      ),
                    },
                  },
                  undefined,
                  { shallow: true })
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <ProductHits
                onResultsChange={handleResultsChange}
                triggerSearchEvent={triggerSearchEvent}
                closeModal={() => setIsOpen(false)}
              />
            </Stack.Item>
            <Stack.Item>
              <Index indexName="suppliers">
                <SupplierHits
                  triggerSearchEvent={triggerSearchEvent}
                  closeModal={() => setIsOpen(false)}
                />
              </Index>
            </Stack.Item>
          </Stack>
        </InstantSearch>
      </Modal>
    </div>
  )
}

export default SearchModal
