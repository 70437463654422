import { forwardRef, useImperativeHandle, useRef } from 'react'
import Link from 'next/link'

import DroppeLogo from '../../Icons/DroppeLogo'
import CountryPicker from './CountryPicker/CountryPicker'
import useIsOnMobile from '../../../services/useIsOnMobile'
import { ScreenSize } from '../../../utils/constants'

import styles from '../../../styles/SimpleHeader.module.less'

const SimpleHeader = forwardRef<HTMLElement>((_, ref) => {
  const countryPickerRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const isOnMobile = useIsOnMobile(ScreenSize.md)

  useImperativeHandle(ref, () => headerRef.current!, [headerRef])

  return (
    <div className={`${styles.header} ${isOnMobile ? styles['mobile-header'] : ''}`}>
      <Link href="/">
        <DroppeLogo />
      </Link>
      <CountryPicker countryPickerRef={countryPickerRef} />
    </div>
  )
})

export default SimpleHeader
