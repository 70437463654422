import useTranslation from 'next-translate/useTranslation'
import Script from 'next/script'
import { useEffect, useRef, useState } from 'react'
import { Button } from 'rsuite'
import { useRouter } from 'next/router'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import CookieIcon from '../Icons/CookieIcon'
import CookieManageOptions from './CookieManageOptions'
import { getCountryAndLocaleStrings } from '../../utils/locales'
import { CookieBannerEventNames, cookieBannerEvent } from '../../utils/analytics/cookieBannerEvents'

import styles from '../../styles/CookieBanner.module.less'
import buttonStyles from '../../styles/CustomButtons.module.less'

const CookieBanner = () => {
  const { t } = useTranslation('common')
  const [shouldShowManageOptions, setShouldShowManageOptions] = useState(false)
  const { locale: countryAndLang } = useRouter()
  const { locale, country } = getCountryAndLocaleStrings(countryAndLang)
  const cookieBannerRef = useRef<HTMLDivElement>(null)

  const showCookieBannerFeatureFlag = useFeatureIsOn('enable-cookie-consent-banner')

  const handleRejectOnClick = () => {
    // Analytics event
    // Other actions are handled by 3rd party library
    cookieBannerEvent({
      eventName: CookieBannerEventNames.REJECT_COOKIES,
      country,
      language: locale,
    })
  }

  const handleAcceptOnClick = () => {
    // Analytics event
    // Other actions are handled by 3rd party library
    cookieBannerEvent({
      eventName: CookieBannerEventNames.ACCEPT_COOKIES,
      country,
      language: locale,
    })
  }

  const handleManageOptionsOnClick = () => {
    setShouldShowManageOptions(true)

    // Analytics event
    cookieBannerEvent({
      eventName: CookieBannerEventNames.MANAGE_COOKIES,
      country,
      language: locale,
    })
  }

  // Analytics event
  useEffect(() => {
    if (cookieBannerRef.current?.style.display !== 'none') {
      cookieBannerEvent({
        eventName: CookieBannerEventNames.VIEW_COOKIE_BANNER,
        country,
        language: locale,
      })
    }
  }, [cookieBannerRef.current?.style.display])

  return (showCookieBannerFeatureFlag
    ? (
      <>
        <div id="cookies-eu-banner" style={{ display: 'none' }} ref={cookieBannerRef}>
          <div aria-hidden="true" className={`rs-modal-backdrop ${styles.backdrop} ${styles['banner-backdrop']}`} />
          <div className={styles['banner-wrapper']}>
            <div className={styles.banner}>
              <div className={styles['banner-header']}>
                <CookieIcon />
                <h4 className="margin-left-spacer margin-bottom-zero">
                  {t('Our website uses cookies')}
                </h4>
              </div>
              <div className={styles['banner-body']}>
                {shouldShowManageOptions ? <CookieManageOptions /> : (
                  <p>
                    {t('We use cookies to improve your user experience')}
                  </p>
                )}
              </div>
              <div className={styles['banner-footer']}>
                {/* NOTE: As 3rd party library is listening to id click */}
                <Button
                  id="cookies-eu-reject"
                  className={shouldShowManageOptions ? styles['reject-button'] : 'hide-element'}
                  appearance="subtle"
                  onClick={handleRejectOnClick}
                >
                  {t('reject')}
                </Button>
                {!shouldShowManageOptions && (
                <Button appearance="subtle" onClick={handleManageOptionsOnClick}>
                  {t('Manage options')}
                </Button>
                )}
                {/* As 3rd party library is listening to id click */}
                <Button
                  type="button"
                  id="cookies-eu-accept"
                  className={`margin-left-spacer
                  ${buttonStyles['custom-button']}
                  ${buttonStyles['button-base-font-family']}
                  ${buttonStyles['button-primary']}`}
                  onClick={handleAcceptOnClick}
                >
                  {t('Accept')}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Script
          src="/scripts/cookie-eu-banner.js"
          strategy="afterInteractive"
          onLoad={() => {
          // @ts-ignore
          // eslint-disable-next-line no-new
            new CookiesEuBanner(() => {
            }, true, false) // (function, waitAccept, useLocalStorage)
          }}
        />
      </>
    )
    : null)
}

export default CookieBanner
