import useTranslation from 'next-translate/useTranslation'
import { Panel } from 'rsuite'
import Link from 'next/link'

import SafeTranslate from '../common/SafeTranslate'

import styles from '../../styles/CookieBanner.module.less'

const ManageOptions = () => {
  const { t } = useTranslation('common')

  const informationList = [
    'Search terms used on the site',
    'Links clicked and products viewed',
    'Products added to your shopping cart',
    'Product information on orders placed',
    'This information helps us to improve our service and provide you with more interesting products and better offers',
  ].map((item) => t(item))

  const panelHeader = `${t('The information collected includes')}:`

  return (
    <>
      <p>
        {t('we collect information about your use of our site')}
      </p>
      <Panel
        header={panelHeader}
        collapsible
        bordered
        className={styles['cookie-manage-panel']}
      >
        <ul>
          {informationList.map((item) => (
            <li key={item}>
              {item}
            </li>
          ))}
        </ul>
      </Panel>
      <div>
        <SafeTranslate
          i18nKey="common:For more information on the use of cookies, please see our <link>privacy policy</link>"
          components={{
            link: <Link href="https://droppe.com/company/tietosuojaseloste" rel="noopener" target="_blank" />,
          }}
        />
        .
      </div>
    </>
  )
}

export default ManageOptions
