import { FC, SyntheticEvent, useState } from 'react'
import { Form, FormControlProps, Schema } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'

import styles from '../../styles/CustomForm.module.less'

interface EmailInputProps extends FormControlProps<HTMLInputElement>{
  shouldUseWorkEmail?: boolean
  placeholder?: string
  autoComplete?: string
  type?: string
  step?: string
  required?: boolean
}

const extractDomainFromEmail = (email: string) => {
  // Regular expression to extract the domain from an email address
  const domainPattern = /@([\w.-]+)$/
  const match = email.trim().match(domainPattern)

  if (match) {
    return match[1].split('.')
  }

  return []
}

const domainList = [
  'hotmail',
  'windowslive',
  'live.com',
  'gmail',
  'outlook',
  'web',
  'yahoo',
  'freenet',
  'gmx',
  't-online',
  'telia',
  'icloud',
  'outlook',
  'inbox',
  'pp5',
  'inet',
  'aol',
  'bluewin',
  'orange',
  'laposte',
  'yandex',
  'seznam',
  'onet',
  'libero',
  'vodafone',
  'eircom',
  'live',
  'hotmail',
  'outlook',
  'gmail',
  'yahoo',
  'protonmail',
  'tutanota',
  'mail',
  'gmx',
  'arcor',
  '1und1',
  'unitybox',
  'luukku',
  'elisa',
  'dnainternet',
]

const EmailInput: FC<EmailInputProps> = ({ shouldUseWorkEmail = true, onChange, ...rest }) => {
  const { t } = useTranslation('modals')
  const [showWarning, setShowWarning] = useState(false)
  const [value, setValue] = useState('')

  const { StringType } = Schema.Types

  const emailModel = Schema.Model({
    email: StringType()
      .isEmail(t('Please enter a valid email'))
      .isRequired(t('The email is required')),
  })

  const handleChange = (newEmail: string, event: SyntheticEvent<Element, Event>) => {
    if (onChange) {
      onChange(newEmail, event)
    }

    if (!shouldUseWorkEmail) return

    const checkResult = emailModel.check({
      email: newEmail.trim(),
    })

    const isInvalidEmail = checkResult.email.hasError

    setShowWarning(false)
    setValue('')

    if (isInvalidEmail) return

    const newEmailDomain = extractDomainFromEmail(newEmail)

    const isMatched = domainList.includes(newEmailDomain[0]?.toLowerCase() || '')
    setValue(newEmailDomain.join('.'))
    setShowWarning(isMatched)
  }

  return (
    <>
      <Form.Control
        onChange={handleChange}
        {...rest}
      />
      {showWarning && (
        <div className={styles['email-domain-warning']}>
          <div>
            {t('Consider using work email instead of {{emailDomain}}', { emailDomain: value })}
          </div>
          <div>
            {t('Droppe only serves customers with a business ID')}
          </div>
        </div>
      )}
    </>
  )
}

export default EmailInput
