import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { Steps } from 'rsuite'

import { isSupplier } from '../../lib/supplier'
import { useAuth } from '../../services/useAuth'
import ProfileDetails from './ProfileDetails'
import SendEmailVerification from './SendEmailVerification'
import InviteUsers from './InviteUsers'
import SupplierProfileOnboarding from './SupplierProfileOnboarding'
import ShippingDetailsOnboarding from './ShippingDetailsOnboarding'
import useShowSupplierOnboarding from '../../services/useShowSupplierOnboarding'
import { SUPPLIER_TERMS_PATH } from '../../utils/constants'

interface Step {
  title: string
  description: string
  isSkippable: boolean
}

const TERMS_DATE = process.env.NEXT_PUBLIC_TERMS_DATE || '2021-01-01 00:00:00'

const ONBOARDING_STEPS: Readonly<Step[]> = [
  {
    title: 'Profile Details',
    description: 'Supplier profile details',
    isSkippable: false,
  },
  {
    title: 'Supplier profile',
    description: 'Add supplier logo, story and country',
    isSkippable: false,
  },
  {
    title: 'Supplier shipping details',
    description: 'Add supplier shipping details',
    isSkippable: false,
  },
  {
    title: 'Email verification',
    description: 'Verify email',
    isSkippable: true,
  },
  {
    title: 'Invite Editors',
    description: 'Invite other people as editors',
    isSkippable: true,
  },
] as const

const Onboarding: React.FC<{ children?: ReactNode }> = (props) => {
  const { children } = props

  const router = useRouter()
  const { user } = useAuth()

  const isShippingDetailsFirstMount = useRef(true)

  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const isSigningTerms = !router.pathname.includes(SUPPLIER_TERMS_PATH)
    && (!user?.terms_signed_at || user?.terms_signed_at < TERMS_DATE)
  const isFinishedOnboarding = currentStepIndex >= ONBOARDING_STEPS.length

  // Redirect to terms page if user has not signed the terms
  useEffect(() => {
    if (isSigningTerms) {
      router.push(SUPPLIER_TERMS_PATH)
    }
  }, [user?.id, isSupplier(user), user?.terms_signed_at])

  const shouldShowOnboarding = useShowSupplierOnboarding(isShippingDetailsFirstMount.current)

  const incrementStep = useCallback(() => setCurrentStepIndex((currStep) => currStep + 1), [])
  const decrementStep = useCallback(() => setCurrentStepIndex((currStep) => currStep - 1), [])

  // Don't show the onboarding in these cases
  if (!shouldShowOnboarding || isFinishedOnboarding) {
    return <>{children}</>
  }

  // List the components presented in the steps, order based om the index
  const onboardingStepsComponents = [
    <ProfileDetails nextAction={incrementStep} />,
    <SupplierProfileOnboarding nextAction={incrementStep} />,
    <ShippingDetailsOnboarding
      nextAction={incrementStep}
      isFirstMount={isShippingDetailsFirstMount}
    />,
    <SendEmailVerification nextAction={incrementStep} />,
    <InviteUsers nextAction={incrementStep} backAction={decrementStep} isInOnboarding />,
  ] as const

  return (
    <div className="max-width-lg">
      <Steps
        current={currentStepIndex}
        className="margin-spacer-double margin-top-zero padding-right-spacer"
      >
        {ONBOARDING_STEPS.map((step) => (
          <Steps.Item
            title={step.title}
            description={step.description}
            key={step.title}
            data-testid={step.title}
          />
        ))}
      </Steps>
      {onboardingStepsComponents[currentStepIndex]}
    </div>
  )
}

export default Onboarding
