import { FC, ReactNode, useRef } from 'react'
import { Content, Form, FormInstance, Panel, Stack } from 'rsuite'

import styles from '../styles/Profile.module.less'

interface ProfileFormWrapperProps {
  schemaModel: any // Sadly, no good way to type the Schema without installing extra packages
  mainHeader: string
  children: ReactNode
  subHeader: string
  changeIndicatorText: string
  isDirty: boolean
  additionalContentClassName?: string
  controlButtonComponent?: ReactNode
}

const ProfileContentWrapper: FC<ProfileFormWrapperProps> = (props) => {
  const {
    schemaModel,
    children,
    controlButtonComponent,
    changeIndicatorText,
    isDirty,
    mainHeader,
    subHeader,
    additionalContentClassName = '',
  } = props

  const form = useRef<FormInstance>()

  return (
    <Content className={`${styles['profile-content']} ${additionalContentClassName}`}>
      <Panel
        bordered
        header={(
          <h1>
            {mainHeader}
          </h1>
        )}
      >
        <Stack alignItems="flex-start" spacing={23}>
          <h5 className="margin-bottom-form-group">
            {subHeader}
          </h5>
          {isDirty && (
            <span className={styles['change-indication-text']}>
              {changeIndicatorText}
            </span>
          )}
        </Stack>
        <Form
          ref={(ref: FormInstance) => { form.current = ref }}
          model={schemaModel}
        >
          <div className={styles['supplier-profile']}>
            {children}
          </div>
        </Form>
        {controlButtonComponent}
      </Panel>
    </Content>
  )
}

export default ProfileContentWrapper
