import { Col, FlexboxGrid, Row } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'
import { FC, ReactNode } from 'react'
import Link from 'next/link'

import SatisfactionGuarantee from '../../SatisfactionGuarantee'
import useInternalTraffic from '../../hooks/useInternalTraffic'
import InternalTrafficButton from '../../InternalTrafficButton'
import useUrls from '../../../services/useUrls'
import DroppeLogo from '../../Icons/DroppeLogo'
import OrderTimeIcon from '../../Icons/OrderTimeIcon'
import ClockIcon from '../../Icons/ClockIcon'
import CarIcon from '../../Icons/CarIcon'

import styles from '../../../styles/Header.module.less'

interface NavItemProps {
  children: ReactNode
  text: string
}

const NavItem: FC<NavItemProps> = ({ children, text }) => {
  const { t } = useTranslation('checkout')

  return (
    <FlexboxGrid.Item className="margin-right-spacer margin-top-spacer">
      {children}
      {text && <span className="margin-left-spacer">{t(text)}</span>}
    </FlexboxGrid.Item>
  )
}

const CheckoutHeader = () => {
  const { isInternalTraffic } = useInternalTraffic()
  const { urlT } = useUrls()

  return (
    <header className={`${styles.header} ${styles['checkout-header']} hide-on-print`}>
      <Row className="max-width-lg">
        <FlexboxGrid align="middle" justify="space-between" className="no-flex-wrap">
          <FlexboxGrid.Item as={Col} lg={5} xl={6}>
            <Link href={urlT('/products')}>
              <DroppeLogo alignment="left" />
            </Link>
          </FlexboxGrid.Item>

          <FlexboxGrid className="flex-grow-1" justify="space-between" align="middle">
            <NavItem text="">
              <SatisfactionGuarantee variant="dark" />
            </NavItem>
            <NavItem text="Quick delivery">
              <CarIcon />
            </NavItem>
            <NavItem text="Net 30 days payment period">
              <ClockIcon />
            </NavItem>
            <NavItem text="products:Business invoicing">
              <OrderTimeIcon className={styles['order-time-icon']} />
            </NavItem>

            {isInternalTraffic && (
              <NavItem text="">
                <InternalTrafficButton />
              </NavItem>
            )}
          </FlexboxGrid>
        </FlexboxGrid>
      </Row>
    </header>
  )
}

export default CheckoutHeader
