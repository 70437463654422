import { FC, RefObject } from 'react'
import { Container, Popover, Whisper } from 'rsuite'
import { useRouter } from 'next/router'

import ProfileIcon from '../../../Icons/ProfileIcon'
import { HOVER_DELAY, MODAL_HASHES } from '../../../../utils/constants'
import ProfileIconPickerContent from './ProfileIconPickerContent'
import useIsUrlHashIncluded from '../../../../services/useIsUrlHashIncluded'

import styles from '../../../../styles/ProfileIconPicker.module.less'

interface ProfileIconPickerProps {
  handleProfileIconClick: (url: string, icon: 'profile' | 'heart') => void
  topHeaderRef?: RefObject<HTMLDivElement>
}

const ProfileIconPicker: FC<ProfileIconPickerProps> = (props) => {
  const { handleProfileIconClick, topHeaderRef } = props

  const { asPath } = useRouter()

  const { LOGIN_URL_HASH, REGISTER_URL_HASH, INVITE_URL_HASH } = MODAL_HASHES
  const isLogoutPage = asPath?.includes('logout')
  const isLoginUrlHashIncluded = useIsUrlHashIncluded([
    LOGIN_URL_HASH,
    REGISTER_URL_HASH,
    INVITE_URL_HASH,
  ])

  const shouldShowPopover = !isLogoutPage && !isLoginUrlHashIncluded

  return (
    <Whisper
      trigger="hover"
      enterable
      placement="bottomEnd"
      container={topHeaderRef ? topHeaderRef.current || undefined : undefined}
      delayOpen={HOVER_DELAY}
      speaker={shouldShowPopover ? (
        <Popover className={styles['picker-popover']}>
          <ProfileIconPickerContent />
        </Popover>
      ) : <div />}
    >
      <Container>
        <ProfileIcon
          className={`cursor-pointer flex-shrink-col-static ${styles['profile-icon']}`}
          onClick={() => handleProfileIconClick('/my-account', 'profile')}
        />
      </Container>
    </Whisper>
  )
}

export default ProfileIconPicker
