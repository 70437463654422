import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { Col, FlexboxGrid, Row } from 'rsuite'

import SafeTranslate from '../../common/SafeTranslate'
import ShieldIcon from '../../Icons/ShieldIcon'

import styles from '../../../styles/Footer.module.less'

const CheckoutFooter: FC = () => {
  const { t } = useTranslation('checkout')

  return (
    <div className={styles['footer-container']}>
      <footer className={`${styles.footer} ${styles['-checkout']} hide-on-print`}>
        <Row className="max-width-lg">
          <Col xl={20} lg={18} md={14}>
            <FlexboxGrid align="middle" className="padding-left-spacer-tripple">
              <ShieldIcon />
              <FlexboxGrid.Item className="margin-left-spacer">
                <SafeTranslate
                  i18nKey="checkout:You are in a safe place Orders are protected by 256-bit SSL encryption"
                  components={{ p: <p /> }}
                />
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Col>
          <Col xl={2} md={4} className="padding-top-spacer" data-testid="checkout-footer-link">
            <Link href="#contact">
              {t('common:Contact us')}
            </Link>
          </Col>
          <Col xl={2} className="padding-top-spacer" data-testid="checkout-footer-link">
            <Link href="https://droppe.com/company/tietosuojaseloste/" target="_blank">
              {t('common:Privacy policy')}
            </Link>
          </Col>
        </Row>
      </footer>
    </div>
  )
}

export default CheckoutFooter
