import { FC, useRef, useState, useEffect } from 'react'
import {
  toaster,
  Button,
  ButtonToolbar,
  FlexboxGrid,
  Form,
  FormInstance,
  Message,
  Panel,
  Schema,
} from 'rsuite'
import { captureException } from '@sentry/nextjs'

import { getSiteCode, useAuth } from '../../services/useAuth'
import { ALERT_DURATION, INVITE_LATER_KEY, LOCAL_STORAGE_USER_KEY } from '../../utils/constants'
import { IAimeosResponse, isValidationError, ValidationError } from '../../utils/types/aimeosApi'
import fetcher from '../../utils/fetcher'
import { StepActions } from '../../utils/types/misc'
import EmailInput from '../Input/EmailInput'

interface InviteUsersProps extends StepActions {
  isInOnboarding?: boolean
}

const { StringType } = Schema.Types
const formSchema = Schema.Model({
  email: StringType()
    .isRequired('This field is required')
    .isEmail('Please enter a valid email.'),
})

const API_URL = process.env.NEXT_PUBLIC_API_URL

const InviteUsers: FC<InviteUsersProps> = (props) => {
  const { nextAction, backAction, isInOnboarding } = props

  const formRef = useRef<FormInstance>(null)
  const { user } = useAuth()

  const [formValue, setFormValue] = useState<Record<string, string>>({
    email: '',
  })
  const [currentMessage, setMessage] = useState<{
    status: 'info' | 'error' | 'success'
    message: string
  }>({ status: 'info', message: '' })
  const [isLoading, setIsLoading] = useState(false)

  const code = getSiteCode(user)

  // check if this step should be skipped by getting an item from localStorage that was previously
  // set, if the user has clicked on the skip button (it's device dependable), and if the user is
  // during the onboarding state. if so, skip this step.
  useEffect(() => {
    if (localStorage.getItem(INVITE_LATER_KEY) && isInOnboarding) {
      nextAction()
    }
  }, [isInOnboarding])

  const companyName = user?.company ? user.company : 'your company'

  const onInviteLaterClick = () => {
    if (!localStorage.getItem(INVITE_LATER_KEY)) {
      localStorage.setItem(INVITE_LATER_KEY, 'true')
    }
    nextAction()
  }

  const sendInvite = async () => {
    if (!formRef?.current?.check()) {
      console.error('Form Error')
      return
    }
    setIsLoading(true)
    const { email } = formValue
    const body = {
      name: user?.name,
      email,
      code,
    }
    try {
      const resp = await fetcher(`${API_URL}/api/v1/invite`, 'POST', JSON.stringify(body))
      if (resp.status === 401) {
        localStorage.removeItem(LOCAL_STORAGE_USER_KEY)
      }
      const data: IAimeosResponse | ValidationError = await resp.json()
      if (isValidationError(data)) {
        setMessage({
          status: 'error',
          message: Object.values(data.errors).join(','),
        })
      } else if (data.status === 'Success') {
        setMessage({ status: 'success', message: data.message })
      } else {
        setMessage({ status: 'error', message: data.message })
        console.error(`Registration failed: ${resp.status}`)
      }
    } catch (error) {
      captureException(error)
      toaster.push(
        <Message type="error" showIcon closable duration={ALERT_DURATION}>
          There was a problem sending the invite
        </Message>,
      )
    }
    setIsLoading(false)
  }

  return (
    <FlexboxGrid justify="center">
      <FlexboxGrid.Item colspan={12}>
        <Panel
          bordered
          header={<h3>{`Do you want to invite more people from ${companyName}?`}</h3>}
        >
          <Form
            onChange={(newFormValue: Record<string, string>) => setFormValue(newFormValue)}
            formValue={formValue}
            ref={formRef}
            checkTrigger="blur"
            model={formSchema}
            noValidate
          >
            <Form.Group>
              <p>
                An email will be sent with instructions on how to set a
                password.
              </p>
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel htmlFor="email">Email</Form.ControlLabel>
              <EmailInput name="email" id="email" type="email" required />
            </Form.Group>
            {currentMessage.message.length > 0 && (
              <Message
                closable
                className="margin-top-spacer margin-bottom-spacer"
                type={currentMessage.status}
                showIcon
              >
                {currentMessage.message}
              </Message>
            )}
            <Form.Group key="buttons">
              <ButtonToolbar>
                {backAction && <Button onClick={backAction}>Previous</Button>}
                <Button
                  appearance="primary"
                  type="submit"
                  onClick={sendInvite}
                  loading={isLoading}
                >
                  Invite
                </Button>
                <Button type="submit" onClick={onInviteLaterClick}>
                  {currentMessage.status === 'success' ? 'Continue' : 'Invite later'}
                </Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </Panel>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  )
}

export default InviteUsers
