import { FC } from 'react'
import Image from 'next/legacy/image'
import { Button, Col, Placeholder } from 'rsuite'
import Link from 'next/link'

import { MegaMenuImageColumn } from '../../../../utils/types/strapi'
import { cmsImageLoader } from '../../../../utils/images'
import useIsOnMobile from '../../../../services/useIsOnMobile'
import { ScreenSize } from '../../../../utils/constants'

import styles from '../../../../styles/MegaMenu.module.less'

interface MegaMenuCtaColumnProps {
  ctaColumnData: MegaMenuImageColumn | undefined
}

const MegaMenuCtaColumn: FC<MegaMenuCtaColumnProps> = (props) => {
  const { ctaColumnData } = props

  const isOnMobile = useIsOnMobile(ScreenSize.lg)

  const { id, image, ctaLink, label } = ctaColumnData || {}
  const { id: imageId, attributes: imageAttributes } = image?.data || {}
  const { alternativeText, formats, caption } = imageAttributes || {}

  const renderHeading = (ctaLabel = '') => {
    const commonProps = {
      className: 'margin-top-spacer margin-bottom-zero margin margin-left-spacer-half',
    }
    if (isOnMobile) return <h6 {...commonProps}>{ctaLabel}</h6>
    return <h5 {...commonProps}>{ctaLabel}</h5>
  }

  return (
    <Col
      md={isOnMobile ? undefined : 6}
      className={`padding-left-zero ${styles['megamenu-cta-column']}`}
      data-testid="megamenu-column"
    >
      {!id ? (
        <>
          <Placeholder.Graph active />
          <Placeholder.Paragraph active rowHeight={15} />
        </>
      ) : (
        <>
          {
          ctaLink && (
            <>
              <Link href={ctaLink.link} shallow legacyBehavior>
                <a className={styles['cta-column-link-content']}>
                  {imageId && (
                    <Image
                      loader={cmsImageLoader}
                      alt={(alternativeText || caption) || ''}
                      src={formats?.small?.url || ''}
                      height={Number(formats?.small?.height)}
                      width={Number(formats?.small?.width)}
                      className={styles['image-container']}
                      layout="responsive"
                    />
                  )}
                  {renderHeading(label)}
                  <Button
                    appearance="link"
                    className={`${styles['cta-link']} padding-left-zero margin-left-spacer-half`}
                  >
                    {ctaLink.label}
                    <img
                      src="/icons/arrow-back.svg"
                      alt="back-arrow"
                      className="margin-left-spacer"
                    />
                  </Button>
                </a>
              </Link>
            </>
          )
        }
        </>
      )}
    </Col>
  )
}

export default MegaMenuCtaColumn
