import { FC } from 'react'
import { FlexboxGrid } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'

import FooterLinksColumn from './FooterLinksColumn'
import FooterBottomRow from './FooterBottomRow'
import FooterLogos from './FooterLogos'
import useFooterData from '../../../services/strapi/useFooterData'
import { StrapiFooterResp } from '../../../utils/types/strapi'
import DroppeLogo from '../../Icons/DroppeLogo'
import SafeTranslate from '../../common/SafeTranslate'

import styles from '../../../styles/Footer.module.less'

interface FooterProps {
  // Workaround to include footer content via SSG
  // TODO: Implement SSG footer globally to Layout component in Next.js 13+
  footerContentResp?: StrapiFooterResp
}

const Footer: FC<FooterProps> = ({ footerContentResp }) => {
  const { t } = useTranslation('footer')
  const { footerColumnData, footerImageData } = useFooterData()

  const columnData = footerContentResp?.data?.[0]?.attributes?.footerColumns || footerColumnData
  const imageData = footerContentResp?.data?.[0]?.attributes?.footerImages || footerImageData

  const images = imageData?.map((img) => ({
    link: img?.imageLink || '',
    src: img?.footerImage?.data?.attributes?.url || '',
    alternativeText: img?.footerImage?.data?.attributes.alternativeText || '',
    width: img?.footerImage?.data?.attributes.width || 0,
    height: img?.footerImage?.data?.attributes.height || 0,
  }))

  return (
    <div className={`${styles['footer-container']}`}>
      <FlexboxGrid as="footer" className={`${styles.footer} hide-on-print`}>
        <div className={styles.row}>
          <div className={styles['slogan-section']}>
            <DroppeLogo className={styles.logo} color="white" size="lg" />
            <p className="h3">
              <SafeTranslate
                i18nKey="common:The most efficient way to source and order supplies for your operations"
                components={{
                  span: <span className="highlight-text" />,
                }}
              />
            </p>
            <p>
              {t('common:Autonomous sourcing platform')}
            </p>
          </div>
          <nav className={styles['link-lists']}>
            {columnData?.map((column) => (
              <FooterLinksColumn
                key={column.id}
                title={column.headline}
                links={column.footerColumnLinks}
              />
            ))}
          </nav>
        </div>
        <div className={`${styles.row} ${styles.logos}`}>
          <FooterLogos images={images || []} />
        </div>
      </FlexboxGrid>
      <FooterBottomRow footerContentResp={footerContentResp} />
    </div>
  )
}

export default Footer
