import { FC, useState } from 'react'
import SearchIcon from '@rsuite/icons/Search'

import SearchModal from './SearchModal'

const MobileSearch: FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <SearchModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isDropdownActive={false}
        isNewSearch
        onClose={() => setIsOpen(false)}
        externalSearchCallback={() => {}}

      />
      <SearchIcon className="icon-size-double" onClick={() => setIsOpen(true)} />
    </>
  )
}

export default MobileSearch
