import { FC, RefObject } from 'react'
import { Container, Popover, Whisper } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'

import CountryPickerTrigger from './CountryPickerTrigger'
import CountryPickerContent from './CountryPickerContent'
import CountryPickerWrapper from './CountryPickerWrapper'
import { HOVER_DELAY, ScreenSize } from '../../../../utils/constants'
import useIsOnMobile from '../../../../services/useIsOnMobile'

import styles from '../../../../styles/CountryPicker.module.less'

interface CountryPickerProps {
  countryPickerRef?: RefObject<HTMLDivElement>
  placement?: 'bottomEnd' | 'topEnd'
  isFlagVisible?: boolean
  className?: string
  showCountryLabelMobile?: boolean
}

const CountryPicker: FC<CountryPickerProps> = (props) => {
  const {
    countryPickerRef,
    placement = 'bottomEnd',
    isFlagVisible = true,
    className = '',
    showCountryLabelMobile,
  } = props

  const { t } = useTranslation('common')

  // TODO: Remove when proper tablet screensize breakpoint designs are implemented
  const isScreenBelowTabletHeaderBreakpoint = useIsOnMobile(ScreenSize.TABLET_HEADER_BREAKPOINT)

  return (
    <CountryPickerWrapper>
      <div
        className={`
          ${styles['country-picker']}
          ${isScreenBelowTabletHeaderBreakpoint ? '' : 'margin-left-spacer-double'} 
          ${isScreenBelowTabletHeaderBreakpoint ? '' : 'margin-right-spacer-double'} 
        `}
        ref={countryPickerRef}
      >
        <Whisper
          trigger="hover"
          enterable
          placement={placement}
          container={countryPickerRef ? countryPickerRef.current || undefined : undefined}
          // TODO: Find proper root cause for country picker flickering and fix re-render issue,
          // delayOpen can be uncommented after fix has been implemented
          // delayOpen={HOVER_DELAY}
          speaker={(
            <Popover className={styles['country-picker-popover']}>
              <CountryPickerContent headerText={t('Choose country and language')} />
            </Popover>
        )}
        >
          <Container className={className}>
            <CountryPickerTrigger
              showCountryLabelMobile={showCountryLabelMobile}
              isFlagVisible={isFlagVisible}
            />
          </Container>
        </Whisper>
      </div>
    </CountryPickerWrapper>
  )
}

export default CountryPicker
