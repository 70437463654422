import isEmpty from 'lodash/isEmpty'

import { CurrencyId, PriceTag, Product, ProductPrice } from '../../utils/types/Product'
import { isDefined } from '../../utils/types/misc'

export interface WithPrice {
  id: string
  price?: ProductPrice[]
}

export const isDiscount = (price: ProductPrice): boolean => (
  price['price.label'].includes('bulk-discount')
)

export const isOldDiscount = (price: ProductPrice): boolean => (
  price['price.label'].includes('pallet-discount')
)

export const hasDiscounts = (prices: ProductPrice[]): boolean => (
  isDefined(prices.find(isDiscount))
)

const filterPricesListByCurrency = (prices: ProductPrice[], currency?: CurrencyId) => (
  prices.filter((price) => price['price.type'] === 'default' && (!currency || price['price.currencyid'] === currency))
)

export const defaultPrice = (
  prices: ProductPrice[],
  currency?: CurrencyId,
): ProductPrice | undefined => (
  filterPricesListByCurrency(prices, currency)
    .find((price) => (!isDiscount(price) && !isOldDiscount(price)))
)

export const getPrice = (
  price: ProductPrice[] | undefined,
  priceType: 'default' | 'cost',
  currency: CurrencyId,
) => price?.find(
  (value) => value['price.type'] === priceType
      && !isDiscount(value)
      && !isOldDiscount(value)
      && value['price.currencyid'] === currency,
)

const isZeroDiscount = (
  price: ProductPrice,
  defaultPriceValue: string | number | undefined,
): boolean => (
  isDiscount(price)
    && (
      price['price.value'] === defaultPriceValue
      || !Number(price['price.quantity'])
    )
)

export const filterZeroDiscountPrices = (prices: ProductPrice[]): ProductPrice[] => (
  prices.filter((price) => (
    !isZeroDiscount(price, defaultPrice(prices, 'EUR')?.['price.value'])
  )))

export const filterLabel = (prices: ProductPrice[], priceTag: PriceTag): ProductPrice[] => (
  prices.filter((price) => price['price.label'].split('|')[0] === priceTag)
)

const dedupePricesByQuantityAndTime = (prices: ProductPrice[]) => Array.from(
  prices.reduce((priceMap, price) => {
    const existingPrice = priceMap.get(price['price.quantity'])
    const currentPriceModifiedDate = new Date(price['price.mtime']!)
    const existingPriceModifiedDate = new Date(existingPrice?.['price.mtime']!)
    if (!existingPrice || (currentPriceModifiedDate > existingPriceModifiedDate)) {
      priceMap.set(price['price.quantity'], price)
    }
    return priceMap
  }, new Map<number, ProductPrice>()).values(),
)

// Price object is the one with lowest quantity
export const getProductPriceObject = (
  product?: Pick<Product, 'price'>,
  currency?: CurrencyId,
): ProductPrice | undefined => {
  const filteredByCurrency = filterPricesListByCurrency((product?.price || []), currency)
  return !isEmpty(filteredByCurrency)
    ? dedupePricesByQuantityAndTime(
      filteredByCurrency,
    ).sort((priceA, priceB) => priceA['price.quantity'] - priceB['price.quantity'])[0]
    : undefined
}

export const getProductPrice = (product?: Pick<Product, 'price'>, currency?: CurrencyId) => Number(getProductPriceObject(product, currency)?.['price.value']) ?? 1

/**
 * Full price taking into account rebate from consumption based take rate,
 * i.e. price with max take rate
 * @param product
 * @param currency
 */
export const getFullProductPrice = (product?: Pick<Product, 'price'>, currency?: CurrencyId): number => (
  Number(getProductPriceObject(product, currency)?.['price.value'])
  + Number(getProductPriceObject(product, currency)?.['price.rebate']) ?? 1)

/**
 * Full price taking into account rebate from consumption based take rate,
 * i.e. price with max take rate
 * @param product
 * @param totalQuantity
 * @param currency
 */
export const getFullPrice = (
  product: Product,
  totalQuantity: number,
  currency?: CurrencyId,
): number => (
  getFullProductPrice(product, currency) * totalQuantity
)
export const getTotalPrice = (
  product: Product,
  totalQuantity: number,
  currency?: CurrencyId,
) => getProductPrice(product, currency) * totalQuantity

export const getCustomPrices = (product: Product) => product.price.filter((price) => price['price.label'].includes('custom_discount'))

export const getLowestCustomPrice = (prices: ProductPrice[]) => {
  const sortedPrices = prices
    .sort((a, b) => parseFloat(a['price.value'].toString()) - parseFloat(b['price.value'].toString()))
  return sortedPrices.length > 0 ? sortedPrices[0] : null
}

export const getSellingPriceValue = (costPrice: number | string, marginValue: number | string) => (
  Number(costPrice) / (1 - (Number(marginValue) / 100))
)

export const getMarginRounded = (costPrice: number, sellingPrice: number): number => (
  Number((((sellingPrice - costPrice) * 100) / sellingPrice).toFixed(2))
)

export const getNewResourcePrice = (priceType: 'default' | 'cost', currency: CurrencyId): ProductPrice => ({
  id: '',
  'price.taxrate': 0,
  'price.value': 0,
  'price.type': priceType,
  'price.quantity': 1,
  'price.label': `Default ${priceType === 'cost' ? priceType : ''}`,
  'price.currencyid': currency,
  'price.costs': 0,
  'price.domain': 'product',
})
